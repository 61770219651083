.item-card {
    flex: auto;
    vertical-align: middle;
    justify-content: start;
    padding: 1rem;
    margin: 1rem;
    background-color: rgb(234, 161, 173);
    border-radius: 0.5rem;
    width: 100%;
}

.item-delete-btn {
    transform: scale(100%);
    transition: 0.2s;
}

.item-delete-btn:hover {
    transition: 0.2s;
    transform: scale(110%) rotate(5deg);
}