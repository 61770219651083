.person-btn {
    transform: scale(100%);
    transition: 0.2s;
}

.person-btn:hover {
    transform: scale(105%);
    cursor: pointer;
    background-color: rgb(237, 203, 208);
    transition: 0.2s;
    border: solid rgb(162, 133, 230) 5px;
}

.clicked {
    background-color: lightblue;
    border: solid rgb(162, 133, 230) 3px;
    transition: 0.2s;
    animation-duration: 0.3s;
    animation-name: click_anim;
    animation-direction: forward;
}

@keyframes click_anim {
    0% {
        transform: scale(100%) rotate(0deg);
    }

    50% {
        transform: scale(120%) rotate(10deg);
    }

    100% {
        transform: scale(100%) rotate(0deg);
    }
}